import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import { Navigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import OAuthSignIn from './pages/OAuthSignIn';
import EuGrantLearnMore from './pages/EUGrantLearnMore';
import Chat from './pages/Chat';
import ChatComponent from './pages/TextStreamingChat';
import { RequireLogin } from '../helper';
import { UserProvider } from '../hooks/useUser';
import { commonRoutes } from '../commonPages/CommonRoutes';
import UserSettings from './pages/UserSettings';

const ThoughtfulOasisIndex = () => {
    return(
        <UserProvider mountingScreen={<></>}>
            <Helmet>
            <meta name="description" content="" />
            <meta name="keywords" content="" />
            <title>Thoughtful Oasis: AI Product Development</title>
        </Helmet>
            <BrowserRouter future={{ v7_startTransition: true }} basename={''}>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/chat" element={<Chat />} />
                    <Route path="/streaming-chat" element={<ChatComponent />} />
                    <Route path="eu-grant-learn-more" element={<RequireLogin><EuGrantLearnMore /></RequireLogin>} />
                    <Route path="settings" element={<RequireLogin><UserSettings /></RequireLogin>} />,    
                    <Route path="oauth-signup" element={<OAuthSignIn />} />
                    <Route path="*" element={<Navigate to="/" />} />
                    {commonRoutes}
                </Routes>
            </BrowserRouter>
        </UserProvider>
    )
}

export default ThoughtfulOasisIndex;