import './index.css';
import reportWebVitals from './reportWebVitals';

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import ThoughtfulOasisIndex from './thoughtful-oasis';
import FeistyPlaceHolder from './feisty-forms/FeistyFormsIndex';



// Check the subdomain
let subdomain = window.location.hostname.split('.')[0];
// Check if host is localhost
const isLocalhost = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1' || window.location.hostname === '192.168.178.225';
if(isLocalhost){
  console.log("Setting subdomain to : ", process.env.REACT_APP_CURRENT_SUBDOMAIN);
  // set to CURRENT_SUBDOMAIN environment variable
  subdomain = process.env.REACT_APP_CURRENT_SUBDOMAIN!;
}
const Application = () => {
  if (subdomain === 'feistyforms') {
    return <FeistyPlaceHolder />
  }else{
    return <ThoughtfulOasisIndex />
  }
}


ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
      <Application />
  </React.StrictMode>
)


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
